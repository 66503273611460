var $ = require('jquery');

var accHeader = $('.accordion__head')

accHeader.on('click', function(){
  $(this).toggleClass('active')
});

$('.accordion__toggle--chart').click(function(e) {
    e.preventDefault();

    var $this = $(this);

    if ($this.next().hasClass('show')) {
        $this.next().removeClass('show');
        $this.next().slideUp(350);
    } else {
        $this.parent().parent().find('li .inner').removeClass('show');
        $this.parent().parent().find('li .inner').slideUp(350);
        $this.next().toggleClass('show');
        $this.next().slideToggle(350);
    }
});




$('.accordion li').each(function() {

  var $this = $(this);
  var $trigger_table = $this.find('.accordion__toggle--table');
  var $trigger_chart = $this.find('.accordion__toggle--graph');

  $trigger_chart.click(function() {
    $this.removeClass('show--table');
    $this.addClass('show--chart');
  })

$trigger_table.click(function() {
    $this.removeClass('show--chart');
    $this.addClass('show--table');
  })


});