import { CountUp } from "countup.js";
import $ from "jquery";
import ScrollMagic from "scrollmagic";

var scrollController = new ScrollMagic.Controller();


$(".countup-time").each(function () {
  var $self = $(this);
  let counter_value = Number($self.html());

  const options_countup = {
    decimalPlaces: 2,
    decimal: ":",
    duration: 2.8,
  };

  let cu = new CountUp($self[0], counter_value, options_countup);

  const options_scroll = {
    triggerElement: $self[0],
    triggerHook: "onEnter",
    offset: $self.height(),
  };

  new ScrollMagic.Scene(options_scroll)
    .on("start", function () {
      cu.start();
    })
    .addTo(scrollController);
});

$(".countup__number").each(function () {
  var $self = $(this);
  let counter_value = parseValues(Number($self.html()));
  let start_val = this.hasAttribute('data-countup-start-val') ? this.getAttribute('data-countup-start-val') : 0;

  const options_countup = {
    decimal: '',
    duration: 2.8,
    separator: '',
    startVal: start_val
  };



  //float with separator
  if(!isInt(counter_value)) {
    options_countup.decimalPlaces = 1
    options_countup = setSeparators(options_countup)
  } else {
    // integer with separator
    if ($self.hasClass('number--separator')) {
      options_countup = setSeparators(options_countup)
    }
  } else {
    // integer with separator
    if ($self.hasClass('number--decimal')) {
      options_countup.decimalPlaces = 3
      options_countup = setSeparators(options_countup)
    }
  }

  let cu = new CountUp($self[0], counter_value, options_countup);

  const options_scroll = {
    triggerElement: $self[0],
    triggerHook: "onEnter",
    offset: $self.height(),
  };

  new ScrollMagic.Scene(options_scroll)
    .on("start", function () {
      cu.start();
    })
    .addTo(scrollController);
});

/*
 * check if value is integer
 */
function isInt(val) {
  return val % 1 === 0
}

/*
 * parse values to int or float
 */
function parseValues(val) {
  if (isInt(val)) {
    val = parseInt(val)
  } else {
    val = parseFloat(val)
  }
  return val
}


/*
 * check lang and return correct float separators
 */
function setSeparators(options) {
  options.separator = ','
  options.decimal = '.'

  if (document.documentElement.lang == 'de') {
    options.separator = '.'
    options.decimal = ','
  }

  return options
}


