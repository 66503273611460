import 'regenerator-runtime/runtime'

import "/_atoms/countup/countup.ts";
import "/_atoms/toTop/toTop.ts";


import "/_components/accordion/accordion.ts";
import "/_components/scroll-reveal/scroll-reveal.ts";
import "/_components/dd-keyfigures/keyfigures.js";
import "/_components/dd-keyfigures/i18n.js";
import "/_components/navigation/navigation.ts";
