var $ = require('jquery');
var level1_trigger = $('.nav__toggle');
var level1 = $('.nav__level1');
var level2_trigger = $('.js-button--home');
var level1_ul = $('.nav-ul');
var level2 = $('.nav__level2');



level2_trigger.on('click', function () {
  if(level1_ul.hasClass('finanzen') || level1_ul.hasClass('finance')) {
    window.location.pathname = ('/')
  }
  else {
    if(!$(this).hasClass('active')) {
              level2_trigger.removeClass('active');
              $(this).addClass('active')
            }
            else {
              $(this).removeClass('active')
            }
  }
    
})


$('.mobile-trigger').on('click', function () {
  $('.nav__level1__content').toggleClass('is-active');
})
