var $ = require('jquery');
var ScrollMagic = require('scrollmagic');
var scrollController = new ScrollMagic.Controller();


$('.reveal').each(function() {
  var $self = $(this);


  var options_scroll = {
    triggerElement: $self[0],
    // triggerHook: .6,
    triggerHook: 'onEnter',
    offset: 50

  }

  new ScrollMagic.Scene(options_scroll)
  .setClassToggle($self[0], 'is-active')
  .addTo(scrollController);

});


$('.reveal--image').each(function() {
  var $self = $(this);


  var options_scroll = {
    triggerElement: $self[0],
    // triggerHook: .6,
    triggerHook: 'onEnter',
    offset: 50

  }

  new ScrollMagic.Scene(options_scroll)
  .setClassToggle($self[0], 'is-active')
  .addTo(scrollController);

});