import DDKeyFigures from "dd-keyfigures/dist/dd-keyfigures";
import merge from "lodash.merge";
import de from "./locale/de.json";
import en from "./locale/en.json";
import I18N from "./i18n";
import globalOptions from "./global_options";

const i18n = new I18N({ ...de, ...en });

const styles = {
  colorGreen: "#B3C618",
  colorYellow: "#FDD556",
  colorGrey: "#5A5A5A",
  colorOrange: "#E27036",
  colorLightblue: "#81A4BC",
  colorLightgrey: "#C8C8C8",
};

const translations = {
  de: {
    "dd-keyfigures": {
      ui: {
        barChart: "Grafik",
        bar: "Säulen",
      },
    },
  },
  en: {
    "dd-keyfigures": {
      ui: {
        barChart: "Chart",
      },
    },
  },
};

const settings = {
  mode: "accordion",
  locale: i18n.getLocaleID(),
  translations,
  features: {
    lineChart: {
      enabled: false,
    },
    indexToggle: {
      enabled: false,
    },
    legendSwitch: {
      enabled: false,
    },
  },
  highlightRow: 1,
};

const profit = {
  labels: ["2023", "2022", "2021", "2020", "2019"],
  datasets: [
    {
      label: i18n.t("profit.labels.umsatz"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      data: [6144.5, 4925.5, 4797.8, 4042.3, 3898.0],
      order: 1,
      backgroundColor: styles["colorGreen"],
      borderColor: styles["colorGreen"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.forschung"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      yAxisID: "y2",
      order: 0,
      data: [302.4, 279.1, 262.3, 237.4, 243.6],
      backgroundColor: styles["colorYellow"],
      borderColor: styles["colorYellow"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.ebitda"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      yAxisID: "y2",
      type: "line",
      order: 0,
      data: [768.5, 435.7, 532.1, 333.4, 280.3],
      backgroundColor: styles["colorGrey"],
      borderColor: styles["colorGrey"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.ebit"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      yAxisID: "y2",
      data: [567.6, 189.7, 384.9, 185.6, 164.0],
      backgroundColor: styles["colorOrange"],
      borderColor: styles["colorOrange"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.ertragsteuern"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      yAxisID: "y2",
      data: [522.3, 166.3, 357.1, 158.1, 135.7],
      backgroundColor: styles["colorLightblue"],
      borderColor: styles["colorLightblue"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.konzern"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      yAxisID: "y2",
      data: [347.1, 88.1, 272.6, 107.1, 96.3],
      backgroundColor: styles["colorLightgrey"],
      borderColor: styles["colorLightgrey"],
      minimumFractionDigits: 1,
    },
  ],
};

const balance = {
  labels: ["2023", "2022", "2021", "2020", "2019"],
  datasets: [
    {
      label: i18n.t("balance.labels.kapital"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      data: [2104.8, 1847.9, 1717.1, 1464.1, 1417.3],
      backgroundColor: styles["colorGreen"],
      borderColor: styles["colorGreen"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("balance.labels.sachinvenstitionen"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      data: [207.7, 200.6, 194.0, 187.2, 183.3],
      backgroundColor: styles["colorYellow"],
      borderColor: styles["colorYellow"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("balance.labels.bilanzsumme"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      data: [5153.8, 4566.1, 4246.1, 3722.5, 3531.9],
      backgroundColor: styles["colorGrey"],
      borderColor: styles["colorGrey"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("balance.labels.free_cashflow"),
      unit: i18n.t("units.mio_euro"),
      data: [84.6, 22.2, 381.5, 308.1, -138.2],
      backgroundColor: styles["colorLightgrey"],
      borderColor: styles["colorLightgrey"],
      minimumFractionDigits: 1,
    },
  ],
};

const employees = {
  labels: ["2023", "2022", "2021", "2020", "2019"],
  datasets: [
    {
      label: i18n.t("employees.labels.anzahl"),
      unit: i18n.t("units.staff"),
      data: [12131, 12116, 11957, 11395, 11448],
      backgroundColor: styles["colorGreen"],
      borderColor: styles["colorGreen"],
      order: 1,
    },
    {
      label: i18n.t("profit.labels.personalaufwand"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      yAxisID: "y2",
      data: [954.1, 891.5, 819.8, 742.2, 730.3],
      backgroundColor: styles["colorYellow"],
      borderColor: styles["colorYellow"],
      minimumFractionDigits: 1,
    },
  ],
};

const profitOptions = {
  scales: {
    y: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
      suggestedMax: 8000,
      display: "auto",
    },
    y2: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
      display: "auto",
    },
  },
  footnotes: [i18n.t("profit.footnotes.1")],
};

const balanceOptions = {
  scales: {
    y: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
      display: "auto",
    },
    y2: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
      display: "auto",
    },
  },
  footnotes: [i18n.t("balance.footnotes.2")],
};

const employeesOptions = {
  scales: {
    y: {
      title: {
        text: i18n.t("units.staff"),
      },
      suggestedMax: 15000,
      display: "auto",
    },
    y2: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
      display: "auto",
    },
  },
  footnotes: [i18n.t("employees.footnotes.3")],
};

const config = {
  profit: {
    type: "bar",
    title: i18n.t("profit.title"),
    data: profit,
    options: merge({}, globalOptions, profitOptions),
  },
  balance: {
    type: "bar",
    title: i18n.t("balance.title"),
    data: balance,
    options: merge({}, globalOptions, balanceOptions),
  },
  employees: {
    type: "bar",
    title: i18n.t("employees.title"),
    data: employees,
    options: merge({}, globalOptions, employeesOptions),
  },
};

if (document.querySelector('#dd-keyfigures')) {
  const keyfigures = new DDKeyFigures(config, settings);
  keyfigures.init();
}
